import { asRem } from 'lib/css';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import { breadcrumb } from './pages';

const BreadcrumbWrapper = styled.div`

>ul {
  display: flex;
  padding: 0 var(--layout-padding-box);
  margin-bottom: var(--layout-padding-box);
  font-size: ${asRem('10')};
  a {
    font-size: ${asRem('10')};
    padding: 0 ${asRem('10')};
  }

  li {
    text-transform: uppercase;
    &:after {
      content: '|';
      color: var(--color-label);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
`;

export const Breadcrumb = ({ className }) => {
  const location = useLocation();
  const branches = breadcrumb(location.pathname);
  return (
    <BreadcrumbWrapper className={className}>
      <ul>
        {branches.map((branch) => (
          <li key={branch.page.meta.url}>
            <Link
              to={branch.page.meta.urlFor(branch.match.params)}
              className="plain"
            >
              {branch.page.meta.name}
            </Link>
          </li>
        ))}
      </ul>
    </BreadcrumbWrapper>
  );
};

Breadcrumb.propTypes = {
  className: PropTypes.string,
};
