import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { UserContext } from 'core/UserContext';
import { asRem } from 'lib/css';
import { Pages } from 'core/pages';
import { CardView } from 'core/Card';
import { SvgIcon } from 'core/SvgIcon';
import { breakpoint } from 'style/variables';
import { Holidays } from './leave/Holidays';
import { LeaveListView } from './leave/ListView';
import { LeaveBalanceGetQuery } from './leave/query';

const HomeWrapper = styled.div`
  margin: ${asRem(13)};

  >.greetings {
    max-width: ${asRem(320)};
    backdrop-filter: blur(80px);
    margin-top: ${asRem(60)};
    padding: var(--layout-padding);
    border-radius: var(--layout-padding);
    /* https://easings.net */
    transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: scale(0.9);

    h1 {
      color: #111;
      font-size: ${asRem(52)};
      line-height: 1.5;
    }

    &.with-picture {
      h1 {
        margin-top: ${asRem(10)};
      }
    }

    .picture {
      display: inline-block;
      top: ${asRem(20)};
      /* margin-top: ${asRem(-120)}; */
      img {
        width: ${asRem(100)};
        overflow: hidden;
        border-radius: ${asRem(50)};
      }
    }


    &.anim-enter-done {
      transform: unset;
      //https://getcssscan.com/css-box-shadow-examples
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      .picture {
        /* position: absolute; */
        top: ${asRem(20)};
      }
    }
  }

  >.quick-actions {
    margin: var(--layout-padding);
    padding-top: var(--layout-padding);
  }

  >.upcoming-holidays {
    display: flex;
    >.items {
      padding-left: ${asRem(20)};
    }

    >.title {
      margin-right: ${asRem(30)};
    }

    h2 {
      margin-left: var(--layout-padding);
      margin-bottom: 0;
      margin-top: 0;
      font-size: ${asRem(32)};
      line-height: 1.5;
      /* border-bottom: 1px solid; */
      writing-mode: vertical-rl;
      background: var(--color-text);
      color: var(--color-text-rev);
      padding: var(--layout-padding);
      /* text-orientation: sideways; */
    }
    .listview {
      /* margin-top: 0; */
    }
  }

  h1 {
    font-weight: 400;
    line-height: ${asRem(34)};
    margin-bottom: ${asRem(16)};
  }

  .request-leave {
    .cardview-items {
      margin: 0;
      max-width: ${asRem(375)};

      .container {
        position: relative;
        padding: ${asRem(43)} ${asRem(36)} ${asRem(32)};
        margin-bottom: ${asRem(32)};

        svg.left_notes {
          position: absolute;
          top: ${asRem(15)};
          left: 0;
        }

        svg.right_notes {
          position: absolute;
          top: ${asRem(15)};
          right: 0;
          transform: rotateY(180deg);
        }

        .info {
          text-align: center;
          margin-bottom: ${asRem(53)};
          > div:first-child {
            color: var(--color-secondary);
            font-weight: 900;
            font-size: ${asRem(32)};
            line-height: ${asRem(45)};
          }
          > div:last-child {
            font-weight: 500;
            font-size: ${asRem(20)};
            line-height: ${asRem(28)};
          }
        }
        a {
          background-color: var(--color-primary-3);
          border-radius: ${asRem(40)};
          padding: ${asRem(10)} ${asRem(32)} ${asRem(12)};
          font-weight: 700;
          font-size: ${asRem(16)};
          line-height: ${asRem(23)};
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  h2 {
    font-weight: 900;
    font-size: ${asRem(28)};
    line-height: ${asRem(48)};
  }

  .holidays {
    .cardview-title-bar {
      margin-bottom: ${asRem(32)};
    }

    .cardview-items {
      margin: 0;
      display: flex;
      flex-flow: row;
      overflow-X: scroll;

      & > div:nth-child(odd) h4 {
        background-color: var(--color-secondary);
      }
      & > div:nth-child(even) h4 {
        background-color: var(--color-theme-5);
      }

      > div {
        padding: 0;
        flex: 0 0 ${asRem(152)};
        height: ${asRem(220)};
        margin-right: ${asRem(16)};
      }
    }
  }

  h2 {
    margin-bottom: ${asRem(24)};
  }

  .myleaves {
    .cardview-items {
      margin: 0;

      > * {
        padding: 0;
        .rzuser {
          .image {
            flex-basis: auto;
          }
          .name {
            flex-basis: 40%;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        @media screen and (min-width: ${breakpoint.tab_min}) and (max-width: ${breakpoint.tab_max}) {
          max-width: ${asRem(375)};
          margin-right: ${asRem(10)};
          flex: 0 0 45%;
        }
        @media screen and (min-width: ${breakpoint.desktop}) {
          max-width: ${asRem(375)};
          margin-right: ${asRem(10)};
          flex: 0 0 23%;
        }
      }
    }
  }
`;

export const Home = () => {
  const userContext = useContext(UserContext);
  const { user } = userContext;
  const userKey = user.resource.key;
  const variables = { user: { key: userKey } };

  const { data } = useQuery(LeaveBalanceGetQuery, {
    variables: { ...(variables || {}) },
  });

  return (
    <HomeWrapper>
      <h1>{`Hey ${user.profile.first_name} !`}</h1>
      <CardView className="request-leave">
        <div className="container">
          <SvgIcon icon="notes" className="left_notes" />
          <SvgIcon icon="notes" className="right_notes" />
          <div className="info">
            <div>{`${data?.staff_emp_policy_leavebalance_get?.balance?.leaves?.balance || 0} days`}</div>
            <div>Leave balance</div>
          </div>
          <Link to={Pages.staff.leave.create.meta.url}>
            Request Leave
          </Link>
        </div>
      </CardView>
      <h2>My Leaves</h2>
      <LeaveListView
        variables={{
          recent: true,
          upcomming: true,
          onlyParent: true,
        }}
        title=""
        enableDaywise={false}
        showMonthPicker={false}
        className="myleaves"
        showBorder={false}
        emptyMessage={(
          <span>You have no upcoming leaves!</span>
        )}
      />
      <Holidays
        className="holidays"
        showBorder={false}
      />
    </HomeWrapper>
  );
};

Home.propTypes = {
};
