import { gql } from '@apollo/client';

export const BootQuery = gql`
query RumBootQuery {
  rum_boot_emp {
    version
    api_version
    force_refresh
    force_logout
  }
}
`;

export const UserQuery = gql`
query UserQuery($key: String!) {
  staff_emp_user(key: $key) {
    user {
      resource {
        key
      }
      name {
        first_name
        last_name
        picture_url
      }
    } 
  }
}
`;

export const EnumValuesQuery = gql`
query EnumValuesQuery($name: String!) {
   __type(name: $name) {
     enumValues {
       name 
     }
   }
}
`;

export const TypeDefQuery = gql`
query TypeDefQuery($name: String!) {
  __type(name: $name) {
    inputFields {
      name
      description
      type {
        kind
        name
        description
        possibleTypes {
          kind
          name
        }
        enumValues(includeDeprecated: false) {
          name
        }
        inputFields(includeDeprecated: false) {
          name
          type {
            kind
          }
          defaultValue
        }
        ofType {
          kind
          name
          enumValues(includeDeprecated: false) {
            name
          }
        }
      }
      defaultValue
    }
  }
}
`;
