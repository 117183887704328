import React from 'react';
import PropTypes from 'prop-types';
import { asRem } from 'lib/css';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  
  button {
    border-radius: ${asRem(40)};
    padding: ${asRem(10)} ${asRem(32)} ${asRem(12)};
    font-weight: 700;
    font-size: ${asRem(16)};
    line-height: ${asRem(23)};
    
    &.success {
      background-color: var(--color-primary-3);
      flex: 1;
    }
    &.cancel {
      background-color: var(--color-bg-10);
      flex: 1;
    }
  }

  &.column {
    flex-flow: column;

    button {
      &.success {
        margin-bottom: ${asRem(10)};
      }
    }

    &__reverse {
      flex-flow: column-reverse;

      button {
        &.cancel {
          margin-bottom: ${asRem(10)};
        }
      }
    }
  }

  &.row {
    align-items: center;
    flex-flow: row;

    button {
      &.success {
        margin-right: ${asRem(12)};
      }
    }

    &__reverse {
      flex-flow: row-reverse;

      button {
        &.cancel {
          margin-right: ${asRem(12)};
        }
      }
    }
  }
`;

export const FormButton = React.memo(({
  btnDirection,
  successBtnTxt,
  cancelBtnTxt,
  onSubmit = null,
  onCancel,
}) => {
  return (
    <ButtonWrapper className={btnDirection}>
      {successBtnTxt && (
        onSubmit ? (
          <button type="button" className="success" onClick={onSubmit}>{successBtnTxt}</button>
        ) : (
          <button type="submit" className="success">{successBtnTxt}</button>
        )
      )}
      {cancelBtnTxt && (<button type="button" className="cancel" onClick={onCancel}>{cancelBtnTxt}</button>)}
    </ButtonWrapper>
  );
});

FormButton.propTypes = {
  btnDirection: PropTypes.string,
  successBtnTxt: PropTypes.string,
  cancelBtnTxt: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default FormButton;
