import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import { breakpoint } from 'style/variables';
import { WhiteTheme } from './Theme';
import { SvgIcon } from './SvgIcon';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/prop-types,react/destructuring-assignment
      this.props.children,
      this.el,
    );
  }
}

const DailogWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;

  >.container-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--color-bg-5);
    opacity: 0.9;
  }

  >.container-wrapper {
    position: absolute;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >.container {
      background-color: var(--color-bg-7);
      box-shadow: 0 ${asRem(6)} ${asRem(30)} rgba(0, 0, 0, 0.85);
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: ${asRem(280)};
      position: relative;

      svg.close {
        position: absolute;
        top: ${asRem(8)};
        right: ${asRem(12)};
        cursor: pointer;
      }

      >.header {
        display: flex;
        align-items: center;
        padding: ${asRem(15)} ${asRem(17)};
        position: relative;

        h2 {
          display: block;
          font-size: ${asRem(24)};
          line-height: ${asRem(32)};
          margin-right: ${asRem(10)};
          color: var(--color-text);
        }
      }
      >.footer {
        padding: var(--layout-padding-contained);
        height: ${asRem(80)};

        button {
          margin-right: ${asRem(5)};
        }
      }
  
      >.content {
        flex: 1;
        height: fit-content;
        overflow: scroll;
      }
    }

    @media screen and (min-width: ${breakpoint.mobile_medium}) {
      top: 0;
      > .container {
        border-radius: ${asRem(20)};
      }
    }
  }

  &.bottom {
    >.container-wrapper {
      bottom: 0;
      max-width: ${asRem(650)};
      margin: auto;

      >.container {
        border-radius: ${asRem(20)} ${asRem(20)} 0 0;
        padding: ${asRem(34)} ${asRem(22)} ${asRem(48)};
      }
    }
  }

  &.center {
    >.container-wrapper {
      top: 0;
      bottom: 0;
      width: fit-content;
      margin: auto;

      >.container {
        box-shadow: 0 ${asRem(12)} ${asRem(30)} rgba(0, 0, 0, 0.85);
        border-radius: ${asRem(20)};
      }
    }
  }
`;

export const Dailog = ({
  title, children, footer,
  onClose,
  position = 'center',
  showCloseIcon = true,
}) => {
  const doClose = () => {
    if (onClose) {
      onClose();
    }
  };
  const onBGClick = () => {
    doClose();
  };

  const container = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (container.current && !container.current.contains(e.target)) {
        onBGClick();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  });

  return (
    <Modal>
      <WhiteTheme>
        <DailogWrapper className={`dailog-wrapper ${position}`.trim()}>
          <div
            className="container-bg"
            onClick={onBGClick}
            role="button"
            aria-hidden="true"
          />
          <div className="container-wrapper">
            <div className="container" ref={container}>
              {showCloseIcon && (<SvgIcon className="close" icon="close" onClick={() => doClose()} />)}
              {title && (
                <div className="header">
                  <h2>{title}</h2>
                </div>
              )}
              <div className="content">
                { children }
              </div>
              {footer && (
                <div className="footer">
                  {footer}
                </div>
              )}
            </div>
          </div>
        </DailogWrapper>
      </WhiteTheme>
    </Modal>
  );
};

Dailog.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  position: PropTypes.string,
  showCloseIcon: PropTypes.bool,
};

export const ConfirmDailog = ({
  onConfirm,
  message = 'Are you sure?',
  confirmText = 'Yes, Sure!',
  cancelText = 'Close',
  onCancel = null,
}) => {
  const doConfirm = () => {
    onConfirm();
  };

  const doCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Dailog
      title=""
      showHeader={false}
      onClose={doCancel}
      footer={(
        <>
          <button
            type="button"
            onClick={doConfirm}
            className="primary"
          >
            {confirmText}
          </button>
          <button
            type="button"
            onClick={doCancel}
          >
            {cancelText}
          </button>
        </>
      )}
    >
      {message}
    </Dailog>
  );
};

ConfirmDailog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};
