import { Config } from 'config';
import moment from 'moment';

export function dataFromPath(obj, path, defaultValue) {
  if (!obj) {
    return obj;
  }

  let childObj = obj;
  const parts = path.split('.');
  let part = parts.shift();
  while (part) {
    childObj = childObj[part];
    if (!childObj) {
      return defaultValue;
    }
    part = parts.shift();
  }

  if (childObj === undefined) {
    return defaultValue;
  }
  return childObj;
}

export function setValueOnPath(obj, value, path) {
  if (!obj) {
    return obj;
  }

  let childObj = obj;
  let parent = obj;
  let parentPath = null;
  const parts = path.split('.');
  let part = parts.shift();
  while (part) {
    parent = childObj;
    parentPath = part;
    childObj = childObj[part];
    if (!childObj) {
      return obj;
    }
    part = parts.shift();
  }

  if (childObj === undefined) {
    return obj;
  }
  parent[parentPath] = value;
  return obj;
}

export function stripKeys(obj, keys) {
  if (!obj) return obj;
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (keys && keys.indexOf(key) > -1) return;
    if (key.startsWith('_')) return;
    newObj[key] = obj[key];
    if (typeof obj[key] === 'object') {
      newObj[key] = stripKeys(obj[key]);
    }
  });
  return newObj;
}

export function setValueOnPaths(obj, paths, valFn) {
  const newObj = JSON.parse(JSON.stringify(obj));

  paths.forEach((path) => {
    const val = dataFromPath(obj, path);
    if (val !== undefined) {
      const newVal = valFn(val);
      if (newVal !== undefined) {
        setValueOnPath(newObj, newVal, path);
      }
    }
  });

  return newObj;
}

export function keyed(obj, paths) {
  return setValueOnPaths(obj, paths, (val) => (val === '' ? null : { key: val }));
}

export function unKey(obj, paths) {
  return setValueOnPaths(obj, paths, (val) => {
    if (val.key) {
      return val.key;
    }
    return undefined;
  });
}

export function currencyAsInt(val) {
  return parseInt(parseFloat(val) * Config.currencyFactor, 10);
}

export function intAsCurrency(val) {
  return parseInt(val, 10) / Config.currencyFactor;
}

export function setCurrencyAsInt(obj, paths) {
  return setValueOnPaths(obj, paths, (val) => currencyAsInt(val));
}

export function setIntAsCurrency(obj, paths) {
  return setValueOnPaths(obj, paths, (val) => intAsCurrency(val));
}
export function getLocal() {
  if (typeof window === 'undefined') {
    return Config.Locale;
  }

  if (Config.PreferUserLocal === 1 && navigator && navigator.language) {
    return navigator.language;
  }

  return Config.Locale;
}

export function formatCurrency(amount, currency) {
  const lCurrency = currency || Config.defaultCurrency;

  // currencyDisplay: 'narrowSymbol' - is not supported in Safari 14.0
  // Best approach is to use polyfills https://formatjs.io/docs/polyfills/intl-numberformat
  // But that will add ~15KB to build size. So its better to go for text replace in this case.
  const formater = new Intl.NumberFormat(
    getLocal(), { style: 'currency', currency: lCurrency },
  );

  let formatted = formater.format(Math.round(amount));

  if (getLocal() === 'is-IS' && formatted.startsWith(lCurrency)) {
    // Temporary Bad logic on chrome 🤧 - Since we know only doing for IS and no decimal.
    // In other browsers its working fine. So only for chrome manually swapping and
    // replacing , to . in price.
    // eslint-disable-next-line no-unused-vars
    const [_, price] = formatted.split(lCurrency);
    formatted = [price.trimStart(), lCurrency].join(' ').replace(/,/g, '.');
  }

  if (Config.CurrencySymbol) {
    return formatted.replace(lCurrency, Config.CurrencySymbol);
  }

  return formatted;
}

export function formatMoney({ value, currency }) {
  return formatCurrency(value, currency).replace('.00', '');
}

export function formatIntAsMoney({ value, currency }) {
  return formatMoney({ value: intAsCurrency(value), currency });
}

export function parseDate(date) {
  if (typeof date === 'number') {
    return moment(Number(date) * 1000);
  }
  return moment(date);
}
export function friendlyDate(date, defaultValue, format) {
  if (!date) {
    return defaultValue || '-';
  }
  const m = parseDate(date);
  if (m.year() === moment().year()) {
    return m.format(format || 'ddd, DD MMM');
  }

  return m.format('DD MMM YYYY');
}

export function friendlyDateTime(date, defaultValue, format) {
  if (!date) {
    return defaultValue || '-';
  }
  const m = parseDate(date);
  return m.format(format || 'ddd, DD MMM YYYY, h:mma');
}

export function getDiffBtwDates(start, end, joinBy = '') {
  if (!start || !end) return { label: [0, 'days'].join(joinBy), value: 0 };
  const days = moment(start).diff(moment(end), 'days') + 1;
  return {
    label: days === 1 ? [1, 'day'].join(joinBy) : [days, 'days'].join(joinBy),
    value: days,
  };
}

export function getFromToDateFormat(start, end, options) {
  const { joinBy = ' - ', format = '', defaultValue = null } = options;
  return [
    start && friendlyDate(start, defaultValue, format),
    end && start !== end && friendlyDate(end, defaultValue, format),
  ]?.filter(Boolean)?.join(joinBy);
}

// export function getUserName(firstName, lastName, joinBy = ' ') {
//   return [firstName, lastName].join(joinBy);
// }
