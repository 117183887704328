import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from 'staff/Home';
import { AppContextConsumer, AppContextProvider } from './core/AppContext';
import { AppLayout } from './core/AppLayout';
import { BottomBar } from './core/BottomBar';
import { PrivateContent } from './core/PrivateContent';
import { TopBar } from './core/TopBar';
import { UserContextProvider } from './core/UserContext';
import { StaffRoutes } from './staff/routes';
import { PagesRoutes } from './pages/routes';

function App() {
  return (
    <AppContextProvider value={{}}>
      <UserContextProvider value={{}}>
        <AppContextConsumer>
          {({ viewState }) => (
            <AppLayout
              top={(<TopBar />)}
              bottom={(<BottomBar />)}
              state={viewState}
            >
              <div>
                <Routes>
                  <Route path="pages/*" element={<PagesRoutes />} />
                  <Route path="staff/*" element={<PrivateContent><StaffRoutes /></PrivateContent>} />
                  <Route index element={<PrivateContent><Home /></PrivateContent>} />
                  <Route
                    path="*"
                    element={(
                      <main style={{ padding: '1rem' }}>
                        <p>Found no pages!</p>
                      </main>
                    )}
                  />
                </Routes>
              </div>
            </AppLayout>
          )}
        </AppContextConsumer>
      </UserContextProvider>
    </AppContextProvider>
  );
}

export default App;
