import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import { StatusEmpty } from 'core/Status';

const CardViewWrapper = styled.div`
  display: block;
  .cardview-title-bar {
    font-weight: 900;
    font-size: ${asRem(28)};
    line-height: ${asRem(48)};
    margin-bottom: ${asRem(11)};
  }
  .cardview-items {
    display: flex;
    flex-wrap: wrap;
    margin: ${asRem(0)} ${asRem(22)};

    > *:not(.exclude) {
      display: flex;
      flex-direction: column;
      position: relative;
      border-radius: ${asRem(20)};
      background-color: var(--color-bg-7);
      overflow: hidden;
      position: relative;
      padding: ${asRem(10)};
      margin-bottom: ${asRem(24)};
      width: 100%;
      transition: transform .2s ease-in-out;
    }
  }
  &.top-border {
    .cardview-items { 
      > *:not(.exclude)::before {
        content: '';
        background-color: var(--color-primary-3);
        height: ${asRem(7)};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }
`;

export const CardView = ({
  header,
  title,
  items,
  children,
  customError,
  className = '',
  showBorder = true,
}) => {
  const getErrorTemplate = () => (
    customError ? <>{customError}</> : <StatusEmpty className="cardview-empty" />
  );

  return (
    <CardViewWrapper className={`${className} ${showBorder ? 'top-border' : ''}`.trim()}>
      {(title || header) && (
        <div className="cardview-title-bar">
          {title && (
            <h2>{title}</h2>
          )}
          {header && header(items)}
        </div>
      )}
      {(items?.length || children) ? (
        <div className="cardview-content">
          <div className="cardview-items">
            {children}
          </div>
        </div>
      ) : (
        getErrorTemplate()
      )}
    </CardViewWrapper>
  );
};

CardView.propTypes = {
  header: PropTypes.func,
  title: PropTypes.string,
  items: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  customError: PropTypes.element,
  className: PropTypes.string,
  showBorder: PropTypes.bool,
};
