import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {
  Formik, Form,
} from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { dataFromPath } from 'lib/utils';
import {
  StatusError,
  StatusLoading,
} from './Status';
import { FormButton } from './FormButton';

const CreateEditControllerWrapper = styled.div`
`;

export const CreateEditController = ({
  itemKey,
  initValues,
  schema,
  mutation,
  responseKey,
  prepareVariables,
  children,
  footerContent,
  onSuccess,
  onCancel,
  successBtnTxt,
  cancelBtnTxt,
  btnDirection = 'column',
}) => {
  const formInitValues = {
    ...initValues,
  };

  const [
    doSave,
  ] = useMutation(mutation);

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    doSave({
      variables: {
        key: itemKey || null,
        ...prepareVariables(values),
      },
    }).then((resp) => {
      const respData = resp && resp.data && dataFromPath(resp.data, responseKey);
      console.log('Saved', respData);
      setSubmitting(false);
      if (respData && respData.okay && onSuccess) {
        onSuccess(respData, { ...values, responseKey });
      }
    }).catch(() => {
      setSubmitting(false);
    });
  };

  return (
    <CreateEditControllerWrapper>
      <Formik
        initialValues={formInitValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          values,
          setFieldValue,
        }) => (
          <Form>
            {children && children({ values, setFieldValue })}
            <FormButton
              btnDirection={btnDirection}
              successBtnTxt={successBtnTxt}
              cancelBtnTxt={cancelBtnTxt}
              onCancel={onCancel}
            />
            {footerContent && footerContent({ values, setFieldValue })}
          </Form>
        )}
      </Formik>
    </CreateEditControllerWrapper>
  );
};

CreateEditController.propTypes = {
  itemKey: PropTypes.string,
  initValues: PropTypes.object,
  schema: PropTypes.object.isRequired,
  mutation: PropTypes.object.isRequired,
  prepareVariables: PropTypes.func,
  responseKey: PropTypes.string.isRequired,
  children: PropTypes.func,
  footerContent: PropTypes.func,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  successBtnTxt: PropTypes.string,
  cancelBtnTxt: PropTypes.string,
  btnDirection: PropTypes.string,
};

const GetControllerWrapper = styled.div`
`;

export const GetController = ({
  query,
  responseKey,
  variables,
  render,
  customError = null,
}) => {
  const { key } = useParams();
  const {
    loading, data, error, refetch,
  } = useQuery(query, {
    variables: { key, ...(variables || {}) },
  });
  if (!data && loading) return (<StatusLoading />);
  if (!data && error) return customError ? <>{customError}</> : <StatusError />;
  const item = dataFromPath(data, responseKey);
  if (!item) return (<StatusError message="Something went wrong" />);

  return (
    <GetControllerWrapper>
      {render(item, refetch)}
    </GetControllerWrapper>
  );
};

GetController.propTypes = {
  query: PropTypes.object.isRequired,
  responseKey: PropTypes.string.isRequired,
  variables: PropTypes.object,
  render: PropTypes.func,
  customError: PropTypes.element,
};
