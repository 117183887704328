import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { asRem } from 'lib/css';

const BoxWrapper = styled.div`
  color: var(--color-text);
  background-color: var(--color-bg-2);
  padding: var(--layout-padding-box);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: ${asRem(20)};
  margin-bottom: var(--layout-padding-box);
  /* border: ${asRem(1)} solid var(--color-border); */

  input {
    width: 100%;
  }

  &.center {
    max-width: ${asRem(600)};
    width: 100%;
    margin: auto;
    margin-bottom: var(--layout-padding-box);
  }


  .flex-row {
    display: flex;
    >div {
      flex: 1;
      margin-right: var(--layout-padding-contained);

      &:last-child {
        margin-right: 0;
      }
    }
    >button {
      background: var(--color-primary-2);
      height: fit-content;
      margin: auto 0;
    }
  }

  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  transform: scale(0.8);

  &.anim-enter-done {
    transform: unset;
  }
`;

export const Box = ({
  children,
  center = false,
  className,
}) => {
  const classes = ['rz-box'];
  if (className) {
    classes.push(className);
  }
  if (center) {
    classes.push('center');
  }

  return (
    <CSSTransition
      classNames="anim"
      timeout={200}
      appear
      in
    >
      <BoxWrapper className={classes.join(' ')}>
        {children}
      </BoxWrapper>
    </CSSTransition>
  );
};

Box.propTypes = {
  children: PropTypes.any,
  center: PropTypes.bool,
  className: PropTypes.string,
};

Box.TitleWrapper = styled.div`
  margin-bottom: var(--layout-padding-box);
  &.center {
    text-align: center;
  }
  h4 {
    font-size: ${asRem(16)};
    line-height: ${asRem(21)};
    font-weight: bold;
    a {
      padding: 0;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
    }
  }
  p {
    color: var(--color-label);
    padding: var(--layout-padding-contained);
    padding-left: 0;
    font-style: italic;
    font-size: ${asRem(14)};
  }
`;

Box.Title = ({
  aside, children: title, center = false, note,
}) => {
  const classes = ['rz-box-title'];
  if (center) {
    classes.push('center');
  }
  if (!title && !aside) return null;

  return (
    <Box.TitleWrapper className={classes.join(' ')}>
      <h4>
        {title}
      </h4>
      {aside && (
        <aside>{aside}</aside>
      )}
      {note && (
        <p>{note}</p>
      )}
    </Box.TitleWrapper>
  );
};

Box.Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  aside: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  note: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  center: PropTypes.bool,
};

Box.ContentWrapper = styled.div`
  h4 {
    font-size: ${asRem(16)};
    line-height: ${asRem(21)};
    font-weight: bold;
  }
`;

Box.Content = ({ className, children }) => {
  return (
    <Box.ContentWrapper className={className}>
      {children}
    </Box.ContentWrapper>
  );
};

Box.Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
