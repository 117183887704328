import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StatusEmpty } from 'core/Status';
import { asRem } from 'lib/css';

const ListViewWrapper = styled.div`
h2 {
  font-size: ${asRem(24)};
  line-height: 1.2;
  padding-bottom: var(--layout-padding-contained);
  /* margin-bottom: var(--layout-padding-contained); */
}

>.listview-title-bar {
  border-bottom: ${asRem(1)} solid var(--color-border);
  display: flex;
  margin-bottom: var(--layout-padding);

  .actions {
    margin-left: auto;
    button {
      margin-left: ${asRem(10)};
    }
  }
}
>.listview-content {
  >.listview-items {
    table {
      width: 100%;
      font-size: ${asRem(14)};
      line-height: ${asRem(20)};

      thead {
        td {
          font-weight: 500;
          border: none;
          height: ${asRem(60)};
          text-align: center;
          vertical-align: middle;
        }
      }

      tbody {
        tr {
          background-color: var(--color-bg-7);
          td {
            vertical-align: middle;
            height: ${asRem(60)};
            padding: ${asRem(12)};
          }

          &:first-child {
            td {
              border-top: 1px solid var(--color-border-2);

              &:first-child {
                border-radius: ${asRem(10)} 0 0;
              }
              &:last-child {
                border-radius: 0 ${asRem(10)} 0 0;
              }
            }
          }

          &:last-child {
            td {
              border-bottom: 1px solid var(--color-border-2);

              &:first-child {
                border-radius: 0 0 0 ${asRem(10)};
              }
              &:last-child {
                border-radius: 0 0 ${asRem(10)} 0;
              }
            }
          }

          &:not(:last-child) {
            td {
              border-bottom: 8px solid var(--color-bg-5);
            }
          }

          &:only-child {
            td {
              border: none;
              border-bottom: 1px solid var(--color-border-2);
              &:first-child {
                border-radius: ${asRem(10)} 0 0 ${asRem(10)};
              }
              &:last-child {
                border-radius: 0 ${asRem(10)} ${asRem(10)} 0;
              }
            }
          }
        }
      }
    }
    .page-tools {
      margin: var(--layout-padding-contained) 0;
      display: flex;
      flex-direction: row-reverse;
      button {
        display: block;
      }
    }
  }
}
`;

export const ListView = ({
  title, items, actions, children,
}) => {
  return (
    <ListViewWrapper className="listview">
      {(title || actions) && (
        <div className="listview-title-bar">
          {title && (
            <h2>{title}</h2>
          )}
          <div className="actions">
            {actions}
          </div>
        </div>
      )}
      <div className="listview-content">
        {items.length
          ? (
            <div className="listview-items">{children}</div>
          )
          : (
            <StatusEmpty className="listview-empty" />
          )}
      </div>
    </ListViewWrapper>
  );
};

ListView.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  actions: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
