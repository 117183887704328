import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ListController } from 'core/ListController';
import { useMonthPicker } from 'core/hooks';
import { RzSelect } from 'core/Select';
import { UserContext } from 'core/UserContext';
import { User } from 'core/User';
import { friendlyDateTime } from 'lib/utils';
import { asRem } from 'lib/css';
import { CalendarLeaveListQuery } from './query';
import { MyLeavesView } from './MyLeavesView';
import { NoLeaves } from './NoLeaves';

const ApproverViewWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${asRem(5)} 0;
  .ts {
    font-size: ${asRem(14)};
    color: var(--color-silent);
    font-style: italic;
  }
  p {
    font-size: ${asRem(14)};
    font-style: italic;
  }
`;

const ApproverView = ({ approver }) => {
  return (
    <ApproverViewWrapper className="approver">
      <div>
        {approver.status === 'Approved' ? '👍 ' : ''}
        {approver.status}
        {approver.status === 'Requested' ? ' to ' : ' by '}
      </div>
      <User userKey={approver.user_key} />
      <div className="ts">
        @
        {friendlyDateTime(approver.at)}
      </div>
      {approver.notes && (
        <div>
          <p>{approver.notes}</p>
        </div>
      )}
    </ApproverViewWrapper>
  );
};

ApproverView.propTypes = {
  approver: PropTypes.object,
};

const LeaveListViewWrapper = styled.div`
  margin-bottom: ${asRem(32)};
  >.top-tools {
    // margin: var(--layout-padding);
    display: flex;
    justify-content: space-between;
    >.months {
      margin-right: ${asRem(15)};
    }
  }
  .event-item {
    margin: var(--layout-padding) 0;

    h4 {
      font-size: ${asRem(28)};
      line-height: 1.2;
      border-bottom: 1px solid;
      margin-bottom: ${asRem(15)};
    }

    .line-two, .notes, .actions, .approvers, .approved {
      /* padding-left: ${asRem(15)}; */
      padding-bottom: ${asRem(5)}
    }

    .line-two {
      display: flex;
      align-items: center;
      h5 {
        padding-right: ${asRem(5)};
      }
    }

    h5 {
      font-size: ${asRem(14)};
    }

    .requested {
      font-size: ${asRem(14)};
      color: var(--color-silent);
      font-style: italic;
    }

    .notes {
      font-size: ${asRem(14)};
      font-style: italic;
      border-left: ${asRem(4)} solid var(--color-highlight);
      padding: ${asRem(5)};
    }
    
  }
`;

export const LeaveListView = ({
  responseKey,
  query,
  variables,
  showMonthPicker = false,
  enableDaywise = false,
  title = null,
  showProfileView = true,
  userKey = null,
  message,
  ...rest
}) => {
  const userContext = useContext(UserContext);
  const { user } = userContext;
  const ruserKey = userKey ?? user.resource.key;
  const [showDaywise, setShowDaywise] = useState(false);
  const {
    month,
    setMonth,
    months,
  } = useMonthPicker({ includeFuture: true });

  let finalVariables = {
    user: { key: ruserKey },
  };

  if (variables) {
    finalVariables = {
      ...finalVariables,
      ...variables,
    };
  } else {
    finalVariables = {
      ...finalVariables,
      month: month.month,
      onlyParent: !showDaywise,
    };
  }

  return (
    <LeaveListViewWrapper>
      <div className="top-tools">
        {showMonthPicker && (
          <div className="months">
            <RzSelect
              options={months}
              defaultValue={month.name}
              keyPath="name"
              valuePath="name"
              onChange={(val) => setMonth(val)}
            />
          </div>
        )}
        {enableDaywise && (
          <button
            type="button"
            className="plain"
            onClick={() => setShowDaywise(!showDaywise)}
          >
            Show
            {showDaywise ? ' Grouped' : ' Day wise'}
          </button>
        )}
      </div>
      <ListController
        query={query || CalendarLeaveListQuery}
        responseKey={responseKey || 'staff_emp_calendar_leave'}
        itemsKey="events"
        title={title === null ? `Leaves - ${month.name}` : title}
        variables={finalVariables}
        useTable={false}
        rows={(items) => {
          return items?.length ? items.map((item) => (
            <MyLeavesView
              key={item.resource.key}
              leaveItem={item}
              showProfileView={showProfileView}
            />
          )) : (
            <NoLeaves
              message={message}
            />
          );
        }}
        {...rest}
      />
    </LeaveListViewWrapper>
  );
};

LeaveListView.propTypes = {
  responseKey: PropTypes.string,
  query: PropTypes.object,
  variables: PropTypes.object,
  title: PropTypes.string,
  userKey: PropTypes.string,
  showMonthPicker: PropTypes.bool,
  enableDaywise: PropTypes.bool,
  showProfileView: PropTypes.bool,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};

export default LeaveListView;
