import { createGlobalStyle } from 'styled-components';
import { asRem } from 'lib/css';

export const breakpoint = {
  mobile_medium: asRem(650),
  tab_min: asRem(768),
  tab_medium: asRem(820),
  tab_max: asRem(1023),
  desktop: asRem(1024),
};

export const GlobaVariables = createGlobalStyle`

:root {
  --font-family: bigcity-grotesque-pro, sans-serif;
  --font-hero: 'Hero New', hero-new, sans-serif;
  --font-numbers: sans-serif;

  --color-bg: #fddb92;
  --color-bg-2: #d1fdff;
  --color-bg-3: #eee;
  --color-bg-4: #f5f5f5;
  --color-bg-5: #121212;
  --color-bg-6: #0C0C0C;
  --color-bg-7: #212121;
  --color-bg-8: #2E2E2E;
  --color-bg-9: #303030;
  --color-bg-10: #424242;
  --color-bg-11: #242426;
  --color-bg-12: #D9D9D9;
  --color-text: #111;
  --color-text-rev: #fff;
  --color-text-2: #f4f4f4;
  --color-button-text: #cbfff3;
  --color-button-bg: #fff;
  --color-link: #6B9FD9;
  --color-primary: #019984;
  --color-primary-2: rebeccapurple;
  --color-primary-3: #D05E28;
  --color-secondary: #F8D78E;
  --color-label: #989899;
  --color-label-2: #777;
  --color-disabled: #959595;
  --color-border: #282934;
  --color-border-2: #a9a9a9;
  --color-highlight: #FF95E8;
  --color-error: #c12424;
  --color-error-2: #AA0B17;
  --color-success: #096C5C;
  --color-silent: #353537ad;
  --color-grey: #BDBDBD;
  --color-light-grey: #F5F5F5;
  --color-theme-1: #EEEEEE;
  --color-theme-2: #74B566;
  --color-theme-3: #ED6C73;
  --color-theme-4: #9E9E9E;
  --color-theme-5: #DFC2B5;
  --color-border-1: #616161;
  --color-border-2: #757575;

  --layout-top-height: ${asRem('74')};
  --layout-left-width: ${asRem('200')};
  --layout-right-width: ${asRem('200')};
  --layout-bottom-height: ${asRem('60')};
  --layout-content-max-width: ${asRem('620')};

  --layout-padding: ${asRem('30')};
  --layout-padding-box: ${asRem('20')};
  --layout-padding-contained: ${asRem('15')};



  --color-bg-8: #1a1a1a;
  --color-button-text: #fff;
  --color-link: #19416C;
  --color-primary: #9034da;
  --color-primary-3: #9034da;
  --color-secondary: #fff;
  --color-bg-5: #000;
  --color-bg-7: #1a1a1a;
  
  --color-text-2: #BD73E8;
}
`;
