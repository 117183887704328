import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Config } from 'config';
import { Box } from 'core/Box';

const StatusLoadingWrapper = styled.div`
  color: var(--color-primary);
  font-style: italic;
  >.rz-box {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  }
`;

export const StatusLoading = ({ message }) => {
  const msg = message || 'Loading...';
  return (
    <StatusLoadingWrapper>
      <Box center>
        {msg}
      </Box>
    </StatusLoadingWrapper>
  );
};

StatusLoading.propTypes = {
  message: PropTypes.string,
};

const StatusErrorWrapper = styled.div`
  color: var(--color-error);
  font-style: italic;

  >.rz-box {
    box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
  }
`;

export const StatusError = ({ error, log = true }) => {
  if (!Config.IsProd && log) {
    console.log('Error');
    console.error(error);
  }
  const errors = error
    && error.networkError
    && error.networkError.result
    && error.networkError.result.errors;

  return (
    <StatusErrorWrapper>
      <Box center>
        {error ? (error.message || error.msg) : 'Oops something is wrong!'}
        {errors && errors.map((err, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>
            {err.message}
          </div>
        ))}
      </Box>
    </StatusErrorWrapper>
  );
};

StatusError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    msg: PropTypes.string,
    networkError: PropTypes.object,
  }),
  log: PropTypes.bool,
};

const StatusEmptyWrapper = styled.div`
  color: var(--color-primary);
  font-style: italic;
  text-align: center;
`;

export const StatusEmpty = ({ message, children, className }) => {
  const msg = message || 'You got nothing here!';
  return (
    <StatusEmptyWrapper>
      <Box center className={className}>
        {children || msg}
      </Box>
    </StatusEmptyWrapper>
  );
};

StatusEmpty.propTypes = {
  message: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
};

const ResponseStatusWrapper = styled.div`
  color: var(--color-primary);
  margin-bottom: var(--layout-padding-contained);
`;

export const ResponseStatus = ({ status }) => {
  return (
    <ResponseStatusWrapper>
      {status && status.okay
        ? (
          <div>✅ All done!</div>
        )
        : (
          <div>
            {!status && (
              <StatusError
                error={{ message: 'Status missing' }}
                log={false}
              />
            )}
          </div>
        )}
      <>
        {status && status.errors && status.errors.map((item, i) => (
          <StatusError
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            error={{ message: item.msg }}
            log={false}
          />
        )) }
      </>
    </ResponseStatusWrapper>
  );
};

ResponseStatus.propTypes = {
  status: PropTypes.shape({
    okay: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.shape({
      msg: PropTypes.string.isRequired,
      code: PropTypes.string,
    })),
  }),
};
