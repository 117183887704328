import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { UserQuery } from 'core/query';
import { asRem } from 'lib/css';

const UserWrapper = styled.div`
  display: flex;
  align-items: baseline;

  >.image {
    width: ${asRem(18)};
    height: ${asRem(18)};
    border-radius: ${asRem(4)};
    margin: 0 ${asRem(4)};
    /* align-self: center;
    position: relative; */

    img {
      /* position: absolute; */
      height: 100%;
      width: 100%;
      border-radius: 50%;
      /* top: 0;
      left: 0;
      right: 0;
      bottom: 0; */
    }
  }

  &.blur {
    > .image {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--color-bg-9);
        filter: blur(6px);
        border-radius: 50%;
        transform: scale(1.16);
      }
    }
  }
`;

export const User = ({
  user,
  userKey,
  render,
}) => {
  const fetchKey = user ? user.key : userKey;
  const { loading, data, error } = useQuery(UserQuery, {
    variables: { key: fetchKey },
  });
  const isLoading = !data && loading;
  const isError = !data && error;
  const userName = data && data.staff_emp_user && data.staff_emp_user.user;
  return (
    <UserWrapper className="rzuser">
      {isLoading && (<span className="loading">...</span>)}
      {isError && (<span className="error">Error</span>)}
      {userName && (
        <>
          {render && render(userName)}
          {!render && (
            <>
              <div className="image">
                {userName.name.picture_url && (
                  <img
                    src={userName.name.picture_url}
                    alt={`${userName.name.first_name}`}
                  />
                )}
              </div>
              <div className="name">
                {[userName.name.first_name, userName.name.last_name].join(' ')}
              </div>
            </>
          )}
        </>
      )}
    </UserWrapper>
  );
};

User.propTypes = {
  user: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }),
  userKey: PropTypes.string,
  render: PropTypes.func,
};
