import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Field, ErrorMessage,
} from 'formik';
import { useQuery } from '@apollo/client';
import { dataFromPath } from 'lib/utils';
import { FormItemWrapper } from 'core/FormItem';
import { asRem } from 'lib/css';
import { SvgIcon } from './SvgIcon';

const RzSelectWrapper = styled.div`
  width: fit-content;
  select {
    padding: ${asRem(12)};
    padding-right: ${asRem(12 + 20)};
  }
`;

export const RzSelect = ({
  options,
  onChange,
  defaultValue,
  keyPath = 'key',
  valuePath = 'name',
  showEmpty = false,
}) => {
  return (
    <RzSelectWrapper>
      <div className="rz-select">
        <select
          onChange={(event) => {
            if (onChange) {
              const val = event.target.value;
              if (val === '-') {
                onChange(null);
              } else {
                const item = options.find((x) => `${dataFromPath(x, keyPath)}` === `${val}`);
                onChange(item);
              }
            }
          }}
          defaultValue={`${defaultValue}`}
        >
          {showEmpty && (
            <option value="-">-</option>
          )}
          {options.map((item) => (
            <option
              key={dataFromPath(item, keyPath)}
              value={`${dataFromPath(item, keyPath)}`}
            >
              {dataFromPath(item, valuePath)}
            </option>
          ))}
        </select>
        <div className="dropdown">
          <SvgIcon icon="down_arrow" />
        </div>
      </div>
    </RzSelectWrapper>
  );
};

RzSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  keyPath: PropTypes.string,
  valuePath: PropTypes.string,
  showEmpty: PropTypes.bool,
};

const ServerSelectWrapper = styled.div`
`;

export const ServerSelectFormItem = ({
  query,
  name,
  label,
  help,
  dataPath,
  valuePath,
  keyPath = 'resource.key',
  showEmpty = true,
}) => {
  const { loading, data, error } = useQuery(query);
  const showLoading = loading && !data;
  const showError = error && !showLoading && !data;
  const showData = !showLoading && !showError;
  const listData = showData && dataFromPath(data, dataPath);
  const resolvedLabel = label || name;

  return (
    <ServerSelectWrapper>
      <FormItemWrapper>
        <label htmlFor={name}>
          {resolvedLabel}
          {showLoading && (<div className="loading">...</div>)}
          {showError && (
          <div className="error">
            Error loading
            {' '}
            {resolvedLabel}
          </div>
          )}
        </label>
        {showData && (
          <>
            <div className="rz-select">
              <Field name={name} component="select">
                {showEmpty && (
                  <option value="">-</option>
                )}
                {listData.map((item) => (
                  <option
                    key={dataFromPath(item, keyPath)}
                    value={dataFromPath(item, keyPath)}
                  >
                    {dataFromPath(item, valuePath)}
                  </option>
                ))}
              </Field>
              <div className="dropdown">▼</div>
            </div>
            <ErrorMessage name={name} className="error-item" component="div" />
            {help && (
              <div className="help">
                {help}
              </div>
            )}
          </>
        )}
      </FormItemWrapper>
    </ServerSelectWrapper>
  );
};

ServerSelectFormItem.propTypes = {
  query: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  help: PropTypes.string,
  dataPath: PropTypes.string,
  valuePath: PropTypes.string,
  keyPath: PropTypes.string,
  showEmpty: PropTypes.bool,
};
