import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const SalaryList = lazy(() => import('./List'));
const SalaryView = lazy(() => import('./SalaryView'));
const AnnualCTCList = lazy(() => import('./AnnualCTCList'));
const AnnualCTC = lazy(() => import('./AnnualCTC'));

export const SalaryRoutes = () => {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route
          index
          element={(<SalaryList />)}
        />
        <Route path={Pages.staff.salary.ctc.meta.starPath}>
          <Route
            index
            element={(<AnnualCTCList />)}
          />
          <Route
            path={Pages.staff.salary.ctc.view.meta.path}
            element={(<AnnualCTC />)}
          />
        </Route>
        <Route path={Pages.staff.salary.month.meta.starPath}>
          <Route
            path={Pages.staff.salary.month.view.meta.path}
            element={(<SalaryView />)}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};
