import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';
// import { Breadcrumb } from 'core/Breadcrumb';
import { SalaryRoutes } from './salary/routes';
import { LeaveRoutes } from './leave/routes';

const Holidays = lazy(() => import('./leave/Holidays'));
const Profile = lazy(() => import('./Profile'));

export const StaffRoutes = () => {
  return (
    <Suspense fallback={<>...</>}>
      <div>
        {/* <Breadcrumb className="hide-on-fullscreen" /> */}
        <Routes>
          {/* <Route path={Pages.staff.salary.meta.path}>
            <Route
              index
              element={(<LeaveBalance />)}
            />
          </Route> */}
          <Route path={Pages.staff.salary.meta.starPath} element={<SalaryRoutes />} />
          <Route path={Pages.staff.leave.meta.starPath} element={<LeaveRoutes />} />
          <Route path={Pages.staff.holidays.meta.path} element={<Holidays />} />
          <Route path={Pages.staff.profile.meta.path} element={<Profile />} />
        </Routes>
      </div>
    </Suspense>
  );
};
