/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { FormItem } from 'core/FormItem';
import { CreateEditController } from 'core/EditController';
import { User } from 'core/User';
import { asRem } from 'lib/css';
import { CalendarLeaveCancelMutation } from './query';

const LeaveCancelWrapper = styled.div`
  .rz-box {
    border: none;
    margin-bottom: 0;
    /* padding-bottom: 0; */
  }
  .cancel-content {
    text-align: center;
    margin-bottom: ${asRem(34)};
    .rzuser {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      margin-bottom: ${asRem(24)};

      .image {
        height: ${asRem(72)};
        width: ${asRem(72)};
        border-radius: 50%;
        margin-bottom: ${asRem(5)};
      }
      .name {
        font-weight: 700;
        font-size: ${asRem(16)};
        line-height: ${asRem(23)};
      }
    }
    h2 {
      font-weight: 500;
      font-size: ${asRem(32)};
      line-height: ${asRem(45)};
      margin-bottom: ${asRem(2)};
    }
    h5 {
      font-weight: 400;
      font-size: ${asRem(16)};
      line-height: ${asRem(23)};
      max-width: ${asRem(224)};
      margin: auto;
    }
  }
  label {
    margin-bottom: ${asRem(13)};
    font-weight: 400;
    font-size: ${asRem(14)};
    line-height: ${asRem(20)};
    color: var(--color-theme-1);
  }
  .notes {
    margin-bottom: ${asRem(18)};
    textarea {
      background-color: var(--color-bg-11);
      resize: none;
      height: ${asRem(61)};
      padding: ${asRem(20)};
      border: none !important;
      color: var(--color-theme-4);

      &::placeholder {
        font-weight: 400;
        font-size: ${asRem(14)};
        line-height: ${asRem(20)};
        color: var(--color-theme-4);
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .pretext-notes {
    margin-bottom: ${asRem(32)};
    display: flex;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    button {
      background: none;
      padding: ${asRem(6)} ${asRem(13)} ${asRem(10)};
      border: 1px solid var(--color-bg-10);
      flex: 1 0 auto;
      text-align: center;
      margin-right: ${asRem(8)};
      border-radius: ${asRem(26)};
      font-weight: 400;
      font-size: ${asRem(15)};
      line-height: ${asRem(22)};
      color: var(--color-theme-1);
    }
  }
`;

export const LeaveCancel = React.memo(({
  itemKey,
  initValues,
  onSuccess,
  onCancel,
  user,
  successBtnTxt,
  cancelBtnTxt,
  title,
  subTitle,
  preTxtNotes,
}) => {
  const FormSchema = Yup.object().shape({
    notes: Yup.string().default('').max(1024).required('Required'),
  });

  const formInitValues = {
    ...FormSchema.cast(),
    ...initValues,
  };

  return (
    <LeaveCancelWrapper>
      <CreateEditController
        itemKey={itemKey}
        initValues={formInitValues}
        schema={FormSchema}
        mutation={CalendarLeaveCancelMutation}
        responseKey="staff_emp_calendar_leave_cancel"
        onSuccess={onSuccess}
        onCancel={onCancel}
        successBtnTxt={successBtnTxt}
        cancelBtnTxt={cancelBtnTxt}
        prepareVariables={(values) => {
          return {
            ...values,
            key: itemKey,
          };
        }}
        showDefaultBtn={false}
      >
        {({ setFieldValue }) => {
          return (
            <>
              <div className="cancel-content">
                <User userKey={user?.resource?.key} />
                <h2>{title}</h2>
                <h5>{subTitle}</h5>
              </div>
              <FormItem
                label="Include a message"
                name="notes"
                type="textarea"
                textAreaRows={3}
                className="notes"
                placeholder="Send a message..."
              />
              {preTxtNotes?.length && (
                <div className="pretext-notes">
                  {preTxtNotes.map((note, index) => (
                    <button type="button" key={index} onClick={() => setFieldValue('notes', note)}>
                      {note}
                    </button>
                  ))}
                </div>
              )}
            </>
          );
        }}
      </CreateEditController>
    </LeaveCancelWrapper>
  );
});

LeaveCancel.propTypes = {
  itemKey: PropTypes.string.isRequired,
  initValues: PropTypes.object,
  onSuccess: PropTypes.func,
  user: PropTypes.object,
  onCancel: PropTypes.func,
  successBtnTxt: PropTypes.string,
  cancelBtnTxt: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  preTxtNotes: PropTypes.array,
};
