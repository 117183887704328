import { gql } from '@apollo/client';

export const CalendarLeaveCreateMutation = gql`
mutation CalendarLeaveCreateMutation(
  $user: UserInput!,
  $date_from: RzDate!,
  $date_to: RzDate!,
  $notes: String,
  $kind: LeaveKind,
  $quantity: Float,
  $approved: Boolean,
  $req_at: RzDateTime,
) {
  staff_emp_calendar_leave_create(
    user: $user,
    date_from: $date_from,
    date_to: $date_to,
    notes: $notes,
    kind: $kind,
    quantity: $quantity,
    approved: $approved,
    req_at: $req_at,
  ) {
    okay
    errors {
      code
      msg
    }
    key
  }
}
`;

export const CalendarLeaveCancelMutation = gql`
mutation CalendarLeaveCancelMutation(
  $key: String!,
  $notes: String,
) {
  staff_emp_calendar_leave_cancel(
    key: $key,
    notes: $notes,
  ) {
    okay
    errors {
      code
      msg
    }
    key
  }
}
`;

export const CalendarLeaveApproveMutation = gql`
mutation CalendarLeaveApproveMutation(
  $key: String!,
  $status: LeaveApprovalStatus!,
  $notes: String,
) {
  staff_emp_calendar_leave_approve(
    key: $key,
    status: $status,
    notes: $notes,
  ) {
    okay
    errors {
      code
      msg
    }
    key
  }
}
`;

const LeaveFields = `
  resource { key }
  event {
    date
    name
    day_event
    date_to
    notes
    parent_key
  }
  leave {
    kind
    requested_at
    quantity
    approved_by {
      at
      status
      user_key
      notes
    } 
  }
  status {
    is_approved
    utilized_qty
  }
  ref {
    user { key }
  }
`;

export const CalendarLeaveListQuery = gql`
query CalendarLeaveListQuery(
   $user: UserInput,
   $month: RzDate,
   $start: RzDate,
   $end: RzDate,
   $upcoming: Boolean,
   $onlyParent: Boolean,
   $recent: Boolean,
  ) {
  staff_emp_calendar_leave(
    user: $user,
    month: $month,
    start: $start,
    end: $end,
    upcoming: $upcoming,
    only_parent: $onlyParent,
    recent: $recent,
  ) {
    next_page_key
    count
    events {
      ${LeaveFields}
    }
  }
}
`;

export const CalendarLeaveGetQuery = gql`
query CalendarEventGetQuery($key: String!) {
  staff_emp_calendar_event_get(key: $key) {
    event {
      ${LeaveFields}
    }
  }
}
`;

export const CalendarEventListQuery = gql`
query CalendarEventListQuery(
   $calendarKey: String,
   $pageKey: String,
   $start: RzDate,
   $end: RzDate,
   $upcoming: Boolean,
   $year: Int,
  ) {
  staff_emp_calendar_event(
    calendar_key: $calendarKey,
    page_key: $pageKey,
    start: $start,
    end: $end,
    upcoming: $upcoming,
    year: $year,
  ) {
    next_page_key
    count
    events {
      resource { key }
      event { name, date, notes, kind, date_to }
    }
  }
}
`;

const BalanceFields = `
resource {
  user
  year
  month
}
leaves {
  carried
  earned
  utilized
  balance
  encashed
  lop
  notes
  stats {
    leaves
    annual_leaves
  }
}
`;

export const LeaveBalanceListQuery = gql`
query StaffListQuery(
  $user: UserInput!,
  $monthFrom: RzDate,
  $monthTo: RzDate,
  $year: Int,
) {
  staff_emp_policy_leavebalance(
    user: $user,
    month_from: $monthFrom,
    month_to: $monthTo,
    year: $year,
  ) {
    balances {
      ${BalanceFields}
    }
    status {
      okay
      errors {
        msg
      }
    }
  }
}
`;

export const LeaveBalanceGetQuery = gql`
query StaffListQuery(
  $user: UserInput!,
) {
  staff_emp_policy_leavebalance_get(
    user: $user,
  ) {
    balance {
      ${BalanceFields}
    }
  }
}
`;

export const LeaveHintQuery = gql`
query StaffListQuery(
  $user: UserInput!,
  $dateFrom: RzDate!,
  $dateTo: RzDate!,
) {
  staff_emp_calendar_leave_hint(
    user: $user,
    date_from: $dateFrom,
    date_to: $dateTo,
  ) {
    leaves
    days
    balance {
      ${BalanceFields}
    }
  }
}
`;
