import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { asRem } from 'lib/css';

const WhiteThemeWrapper = styled.div`
  /* --color-text: #15141B;
  --color-text-2: #111;
  --color-bg: #fff;
  --color-bg-2: #fff;
  --color-highlight: #710A5B;
  --color-primary: #1e18ce;
  --color-button-text: #111;
  color: var(--color-text);

  a, button {
    &.primary {
      --color-button-text: #fff;
    }
  }
  
  input, select, textarea {
    border: ${asRem(1)} solid #333;
    background: transparent;

    &:focus {
      border: ${asRem(1)} solid var(--color-highlight);
    }

  }

  input[type=checkbox]{
    border: none;
    opacity: 0.65;

    &:checked {
      opacity: 1;
    }
  }

  .rz-select {
    border: ${asRem(1)} solid #333;
    background: transparent;
  } */
`;

export const WhiteTheme = ({ children }) => {
  return (
    <WhiteThemeWrapper className="white-theme">
      {children}
    </WhiteThemeWrapper>
  );
};

WhiteTheme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
