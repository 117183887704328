import { gql } from '@apollo/client';

export const MyProfileQuery = gql`
query MyProfileQuery {
  staff_emp_staff_get {
    staff {
      resource {
        key
      }
      roles {
        roles
      }
      profile {
        email
        first_name
        last_name
        picture_url
      }
      staff {
        legal_name
        legal_title
        emp_id
        doj
        job_title_key
        job_title
        team_key
        team_name
      }
      work {
        employee_status
        current_office {
          key
        }
        employed_office {
          key
        }
        is_employee_active
        doj
        leave_calendar {
          key
        }
        last_working_day
        custom_leave_policy {
          key
        }
      }
      personal {
        dob
        blood_group
        marital_status
        verified_contact {
          name
          phone
          country_code
          email
          relation
          note
        }
        work_contact {
          name
          phone
          country_code
          email
          relation
          note
        }
        emergency_contacts {
          name
          phone
          country_code
          email
          relation
          note
        }
      }
      visibility {
        salary
      }
    } 
  }
}
`;

export const LoginMutation = gql`
mutation LoginMutation($email: String!, $password: String!) {
  staff_emp_login(email: $email, password: $password) {
    status {
      okay
      errors {
        msg
        code
      }
    }
    token
    require_change_password
  }
}
`;
