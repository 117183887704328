import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { BootQuery } from 'core/query';
import { AppScreenError, AppScreenLoading } from './Screen';

const defaultContext = {
  theme: 'auto',
  boot: null,
  viewState: {
    top: true,
    right: false,
    bottom: true,
    left: false,
    doubelTop: false,
    isFullScreen: false,
  },
};

export const AppContext = React.createContext({ ...defaultContext });
export const AppContextConsumer = AppContext.Consumer;

export const AppContextProvider = ({ value, children }) => {
  const [appContext, setAppContext] = useState({
    setTheme(theme) {
      setAppContext((state) => ({
        ...state,
        theme,
      }));
    },
    setBootData: (boot) => {
      setAppContext((state) => ({
        ...state,
        boot,
      }));
    },
    setViewStateValue: (key, stateValue) => {
      setAppContext((state) => ({
        ...state,
        viewState: {
          ...state.viewState,
          [key]: stateValue,
        },
      }));
    },
    ...defaultContext,
    ...value,
  });

  const { loading, error, data } = useQuery(BootQuery);

  if (loading && !data) return (<AppScreenLoading />);
  if (error && !data) return (<AppScreenError error={error} />);

  if (data && !appContext.boot) {
    appContext.setBootData(data.rum_boot_emp);
  }

  return (
    <AppContext.Provider value={appContext}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.element,
};
