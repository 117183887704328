import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const LeaveCreate = lazy(() => import('./Create'));
const LeavesList = lazy(() => import('./List'));
const LeaveListView = lazy(() => import('./ListView'));
const LeaveBalance = lazy(() => import('./LeaveBalance'));
const LeavePageView = lazy(() => import('./View'));
const LeaveBreakup = lazy(() => import('./LeaveBreakup'));

export const LeaveRoutes = () => {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route
          index
          element={(<LeavesList />)}
        />
        <Route path={Pages.staff.leave.create.meta.path} element={<LeaveCreate />} />
        <Route path={Pages.staff.leave.view.meta.path} element={<LeavePageView />} />
        <Route path={Pages.staff.leave.month.meta.starPath}>
          <Route
            index
            element={(<LeaveBreakup />)}
          />
          <Route path={Pages.staff.leave.month.meta.path} element={<LeaveListView />} />
          <Route path={Pages.staff.leave.month.balance.meta.path} element={<LeaveBalance />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
