import { matchPath } from 'react-router-dom';

export const Pages = {
  staff: {
    leave: {
      index: {},
      create: {},
      view: {
        meta: { path: 'view/:key' },
      },
      month: {
        meta: { path: 'month/:month' },
        view: { },
        balance: { },
      },
    },
    holidays: {
      index: {},
    },
    salary: {
      index: {},
      month: {
        meta: { path: 'month/:month' },
        view: { },
      },
      ctc: {
        index: { },
        view: {
          meta: { path: 'view/:key' },
        },
      },
    },
    profile: {
      index: {},
    },
  },
  pages: {
    onLoginCallback: {},
  },
};

function resolvePages(pageKey, page, parent) {
  const meta = page.meta || {};
  const parentUrl = parent ? `${parent.url}/` : '';
  let { path, name } = meta;

  if (!path) {
    switch (pageKey) {
      case 'index':
        path = '';
        name = parent ? parent.name : 'index';
        break;
      case 'update':
        path = ':key/update';
        break;
      default:
        path = pageKey;
        break;
    }
  }

  const url = `${parentUrl}${path}`;
  const newMeta = {
    ...meta,
    name: name || pageKey,
    url,
    path,
    starPath: `${path}/*`,
    urlFor(params) {
      let urlPatern = this.url;
      Object.keys(params).forEach((key) => {
        urlPatern = urlPatern.replace(`:${key}`, encodeURIComponent(params[key]));
      });
      return urlPatern;
    },
  };

  // eslint-disable-next-line no-param-reassign
  page.meta = newMeta;

  Object.keys(page).forEach((subPageKey) => {
    if (subPageKey === 'meta') return;
    resolvePages(subPageKey, page[subPageKey], newMeta);
  });
}

resolvePages('', Pages, null);

export function breadcrumb(path, parent) {
  const matched = [];
  const resolvedParent = parent || Pages;
  Object.keys(resolvedParent).forEach((pageKey) => {
    if (pageKey === 'meta') return;
    const page = resolvedParent[pageKey];
    const match = matchPath(`${page.meta.url}*`, path);
    const matchFull = matchPath(page.meta.url, path);
    if (match && !matchFull) {
      matched.push({
        page,
        match,
      });
      Object.keys(page).forEach((childPageKey) => {
        if (childPageKey === 'meta') return;
        const childPage = page[childPageKey];
        matched.push(...breadcrumb(path, childPage));
      });
    }
  });

  return matched;
}
