import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Config } from 'config';
import { Box } from 'core/Box';
import { asRem } from 'lib/css';

const AppScreenLoadingWrapper = styled.div`
margin: ${asRem(40)};
`;

export const AppScreenLoading = ({ message }) => {
  const msg = message || 'App is loading...';
  return (
    <AppScreenLoadingWrapper>
      <Box center>
        {msg}
      </Box>
    </AppScreenLoadingWrapper>
  );
};

AppScreenLoading.propTypes = {
  message: PropTypes.string,
};

const AppScreenErrorWrapper = styled.div`
  margin: ${asRem(40)};
  color: var(--color-error);
  font-style: italic;
`;

export const AppScreenError = ({ error }) => {
  if (!Config.IsProd) {
    console.log('App Error');
    console.error(error);
  }
  return (
    <AppScreenErrorWrapper>
      <Box center>
        {error ? error.message : 'Oops something is wrong!'}
      </Box>
    </AppScreenErrorWrapper>
  );
};

AppScreenError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
};
