import React, { useContext } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { asRem } from 'lib/css';
import { AppContext } from './AppContext';
import { Pages } from './pages';
import { SvgIcon } from './SvgIcon';

const BottomBarWrapper = styled.div`
  background-color: var(--color-bg-6);
  background-image: none;
  padding: ${asRem(10)} ${asRem(0)};
  nav {
    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;
      li {
        padding-bottom: 0;
        margin-right: ${asRem(15)};
        font-size: ${asRem(14)};
        line-height: ${asRem(18)};
        &:last-child {
          margin-right: 0;
        }
        a {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--color-text-rev);
          border-radius: 50%;
          height: ${asRem(40)};
          width: ${asRem(40)};
          margin: auto;
          padding: 0;
          border: 0;
          box-shadow: none;
          background: none;

          &.active::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            /* filter: blur(${asRem(4)}); */
            /* background-color: var(--color-bg-9); */
            border-radius: 50%;
          }

          svg {
            z-index: 0;
            margin-bottom: ${asRem(6)};
          }
          
          &.active {
            svg * {
              stroke: var(--color-secondary);
            }
          }

          &.inactive {
            svg * {
              stroke: var(--color-theme-4);
            }
          }
        }
      }
    }
  }
  .version-name {
    display: none;
    opacity: 0.6;
    text-align: right;
    padding-right: ${asRem(10)};
    font-size: ${asRem(10)};
    color: #fff;
  }
`;

export const BottomBar = () => {
  const appContext = useContext(AppContext);
  let path = window.location.pathname.split('/').filter(Boolean);
  if (!path?.length) path = ['home'];

  const links = [
    { to: '/', name: 'home', icon: 'home' },
    { to: Pages.staff.leave.month.balance.meta.urlFor({ month: 'current' }), name: 'leave', icon: 'calendar' },
    { to: Pages.staff.leave.create.meta.url, name: 'add', icon: 'add' },
    { to: Pages.staff.salary.meta.url, name: 'salary', icon: 'salary' },
  ];

  return (
    <BottomBarWrapper>
      <nav>
        <ul>
          {links.map((link) => {
            return (
              <li key={link.to}>
                <NavLink
                  to={link.to}
                  className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                >
                  <SvgIcon icon={link?.icon} strokeWidth={2} />
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      {appContext.boot && (
        <div className="version-name">
          {'v'}
          {appContext.boot.version}
          {` (api v${appContext.boot.api_version})`}
        </div>
      )}
    </BottomBarWrapper>
  );
};

BottomBar.propTypes = {
};
