export const Config = {
  gqlSrc: process.env.REACT_APP_RZ_GQL_SRC,
  apiUrl: process.env.REACT_APP_RZ_API_URL,
  apiKey: process.env.REACT_APP_RZ_API_KEY,
  IsProd: process.env.NODE_ENV === 'production',
  Locale: process.env.REACT_APP_RZ_LOCALE || 'en-US',
  currencyFactor: 1000,
  defaultCurrency: 'INR',
  firstYear: 2020,
  fyMonth: 4,
};
