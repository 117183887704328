import React from 'react';
import { asRem } from 'lib/css';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SvgIcon } from 'core/SvgIcon';

const NoBreakDownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${asRem(30)} 0 !important;
  .month.exclude {
    margin-bottom: ${asRem(12)};
  }
  span {
    font-weight: 700;
    font-size: ${asRem(16)};
    line-height: ${asRem(23)};
    text-align: center;
  }
`;

export const NoLeaves = ({ message }) => {
  return (
    <NoBreakDownWrapper>
      <SvgIcon icon="calendar_error" />
      {message || (<span>You have no leaves!</span>)}
    </NoBreakDownWrapper>
  );
};

NoLeaves.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};

export default NoLeaves;
