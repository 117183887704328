import { createGlobalStyle } from 'styled-components';
import { asRem } from 'lib/css';

export const GlobalStyle = createGlobalStyle`

body {
  font-family: var(--font-family);
  font-weight: 400;
  font-style: normal;
  color: var(--color-theme-1);
  background-color: var(--color-bg-5);
  // background-image: linear-gradient(to left, #fddb92 0%, #d1fdff 100%);
  ${'' /* background-image: linear-gradient(to left, #fad0c4 0%, #ffd1ff 100%);
  background-image: linear-gradient(to left, #fad0c4 0%, #ffd1ff 100%);
  background-image: linear-gradient(to left, #d5dee7 0%, #ffafbd 0%, #c9ffbf 100%); */}
}

@media print {
  body {
    background-color: #fff;
  }
}

input, select, textarea {
  background: var(--color-border);
  color: var(--color-text-2);
  font-size: ${asRem(14)};
  line-height: ${asRem(18)};
  padding: ${asRem(10)} ${asRem(16)};
  border-radius: ${asRem(15)};
  outline: none;
  border: ${asRem(1)} solid transparent;
  display: block;
  margin-bottom: ${asRem(8)};

  &:active, &:focus {
    outline: none;
    border: ${asRem(1)} solid var(--color-highlight);
  }
}

input[type=number] { 
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
  }
}

input[type=checkbox]{
  --checkbox-height: ${asRem(38)};
  display: grid;
  width: var(--checkbox-height);
  height: var(--checkbox-height);
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  border: 0;
  background: transparent;

  &::before, &::after {
    grid-area: 1/1;
    border-radius: ${asRem(8)};
    pointer-events: none;
  }

  &::before {
    background: var(--color-border);
    content: '✓';
    color: #3f4153;
    ${'' /* color: var(--color-label); */}
    font-size: ${asRem(22)};
    line-height: var(--checkbox-height);
    border: ${asRem(1)} solid transparent;
  }

  &::after{
  }

  &:checked {
    &::before{
      background: #FDBFEF;
      color: var(--color-bg);
    }
  }

  &:active, &:focus {
    &::before {
      border: ${asRem(1)} solid var(--color-highlight);
    }
  }
}


textarea { 
  width: 100%;
  appearance: none;
}

label {
  color: var(--color-label);
  display: block;
  margin-bottom: ${asRem(8)};
}

a, button {
  border-radius: ${asRem(15)};
  font-weight: bold;
  font-size: ${asRem(16)};
  border: ${asRem(1)} solid transparent;
  cursor: pointer;
  color: var(--color-button-text);
  text-decoration: none;

  &:hover {
    text-decoration: none;
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--color-disabled);
    opacity: 0.8;
  }

  &.plain {
    color: currentColor;
    background-color: inherit;
    box-shadow: none;

    & svg {
      color: currentColor;
    }

    &:hover {
      text-decoration: none;
      color: var(--color-primary);
    }

    &.hover-underline {
      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  &.compact {
    padding: 0;
    margin-right: ${asRem(10)};
  }
  
  &.primary {
    background: var(--color-primary);
    text-align: center;
    min-height: ${asRem(36)};
  }
}

h1 {
  font-weight: bold;
  font-size: ${asRem(24)};
  line-height: ${asRem(31)};
}

td {
  &.number {
    text-align: right;
    font-family: var(--font-numbers);
  }
}

.rz-select {
  position: relative;
  color: var(--color-text-2);
  select {
    width: 100%;
    margin-bottom: 0;
    appearance: none;
    background-color: var(--color-bg-7);
    border: 0;
    padding: ${asRem(8)} ${asRem(38)} ${asRem(8)} ${asRem(16)};
    font-weight: 500;
    line-height: ${asRem(20)};
    border-radius: ${asRem(20)};
    cursor: pointer;
  }
  >.dropdown {
    width: ${asRem(20)};
    position: absolute;
    right: ${asRem(10)};
    top: calc(50% - ${asRem(7)});
    font-size: ${asRem(10)};
    opacity: 0.7;
    text-align: center;
    pointer-events: none;
  }
}

.switch {
  position: relative;
  display: inline-block;
}
.switch-input {
  display: none;
}
.switch-label {
  display: block;
  width: 48px;
  height: 24px;
  text-indent: -150%;
  clip: rect(0 0 0 0);
  color: transparent;
  user-select: none;
}
.switch-label::before,
.switch-label::after {
  content: "";
  display: block;
  position: absolute;
  cursor: pointer;
}
.switch-label::before {
  width: 100%;
  height: 100%;
  background-color: #dedede;
  border-radius: 9999em;
  -webkit-transition: background-color 0.25s ease;
  transition: background-color 0.25s ease;
}
.switch-label::after {
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
  -webkit-transition: left 0.25s ease;
  transition: left 0.25s ease;
}
.switch-input:checked + .switch-label::before {
  background-color: #89c12d;
}
.switch-input:checked + .switch-label::after {
  left: 24px;
}

@media print {
  body {
    margin:0;
    padding:0;
    print-color-adjust: exact;
  }
  .show-on-print {
    display: block !important;
  }
  .hide-on-print {
    display: none !important;
    &.name {
      display: none;
    }
  }
  .content-container {
    padding: 0 !important;
  }
}
`;
