/* eslint-disable react/no-array-index-key */
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import { UserContext } from 'core/UserContext';
import { asRem } from 'lib/css';
import {
  friendlyDateTime,
  getDiffBtwDates,
  getFromToDateFormat,
} from 'lib/utils';
import { User } from 'core/User';
import { SvgIcon } from 'core/SvgIcon';
import { Dailog } from 'core/Dailog';
import { Pages } from 'core/pages';
import { LeaveCancel } from './Cancel';

const LeaveProfileViewWrapper = styled.div`
  padding: ${asRem(16)} ${asRem(13)};

  .rzuser {
    display: flex;
    align-items: center;
    
    .image {
      flex-basis: 1;
      height: ${asRem(30)};
      width: ${asRem(30)};
      border-radius: 50%;
      margin-right: ${asRem(9)};
      display: block;
    }

    .name {
      flex-basis: 1;
      font-weight: 700;
      font-size: ${asRem(14)};
      line-height: ${asRem(20)};
    }
  }

  .actions, .rzuser  {
    margin-top: ${asRem(16)};
  }

  .actions {
    margin-left: ${asRem(8)};
    
    button {
      font-weight: 700;
      font-size: ${asRem(14)};
      line-height: ${asRem(20)};
      color: var(--color-grey);
      padding: 0;
      border: 0;
      outline: none;
    }
  }

  .date {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    & > span:first-child {
      font-weight: 400;
      font-size: ${asRem(20)};
      line-height: ${asRem(24)};
      margin-bottom: ${asRem(5)};
      a {
        color: var(--color-text-2);
      }
    }

    & > span:last-child {
      font-weight: 400;
      font-size: ${asRem(14)};
      line-height: ${asRem(20)};
      color: var(--color-grey);
    }
  }
`;

const ApproverViewWrapper = styled.div`
  display: flex;
  align-items: end;
  margin-top: ${asRem(16)};
  margin-left: ${asRem(8)};
  
  svg {
    margin-right: ${asRem(8)};
  }
  span {
    font-weight: 400;
    font-size: ${asRem(12)};
    line-height: ${asRem(17)};
    color: var(--color-text-rev);
    opacity: 0.7;
  }
`;

const SuccessDailogWrapper = styled.div`
  padding: ${asRem(24)};
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: ${asRem(252)};
  margin: auto;

  .rzuser {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: ${asRem(26)};

    .image {
      height: ${asRem(72)};
      width: ${asRem(72)};
      margin-bottom: ${asRem(5)};
    }

    .name {
      font-weight: 400;
      font-size: ${asRem(12)};
      line-height: ${asRem(17)};
    }
  }

  .msg {
    display: flex;
    align-items: center;

    span {
      font-weight: 500;
      font-size: ${asRem(16)};
      line-height: ${asRem(23)};
      margin-right: ${asRem(8)};
    }
  }
`;

const LeaveProfileView = ({ user, leaveItem }) => {
  const [showCancel, setShowCancel] = useState(false);
  const [showResponseModal, setResponseModal] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);

  const onSuccess = () => {
    setResponseModal(true);
    setShowCancel(false);
  };

  const cancel = (key) => {
    setSelectedKey(key);
    setShowCancel(true);
  };

  const preCancelTxtNotes = [
    'I’m taking the next day off',
    'My trip got cancelled',
  ];

  return (
    <LeaveProfileViewWrapper>
      {showCancel && (
        <Dailog
          title=""
          onClose={() => setShowCancel(false)}
          showHeader={false}
          position="bottom"
        >
          <LeaveCancel
            user={user}
            itemKey={selectedKey}
            initValues={{}}
            onCancel={() => setShowCancel(false)}
            onSuccess={() => onSuccess()}
            successBtnTxt="Yes, cancel"
            cancelBtnTxt="No"
            title="Request to cancel?"
            subTitle="Your request to cancel leave will be sent to the Admin"
            preTxtNotes={preCancelTxtNotes}
          />
        </Dailog>
      )}
      {showResponseModal && (
        <Dailog
          title=""
          onClose={() => setResponseModal(false)}
        >
          <SuccessDailogWrapper>
            <User userKey={user?.resource?.key} />
            <div className="msg">
              <span>Leave Request Cancelled</span>
              <SvgIcon icon="round_tick" />
            </div>
          </SuccessDailogWrapper>
        </Dailog>
      )}
      <div className="date">
        <span>
          <Link to={Pages.staff.leave.view.meta.urlFor({ key: leaveItem?.resource?.key })}>
            {getFromToDateFormat(leaveItem?.event?.date, leaveItem.event.date_to, { format: 'ddd, MMM DD' })}
          </Link>
        </span>
        <span>
          {`(${getDiffBtwDates(leaveItem?.event?.date_to, leaveItem?.event?.date)?.label})`}
        </span>
      </div>
      <div className="divider" />
      <User userKey={user?.resource?.key} />
      {leaveItem.status.is_approved && leaveItem.leave.approved_by.map((approver, index) => (
        <ApproverViewWrapper key={`${approver?.userKey}_${index}`}>
          <SvgIcon icon="round_tick" />
          <span>
            Approved by
            {' '}
            {[user?.profile?.first_name, user?.profile?.last_name]?.filter(Boolean).join(' ')}
          </span>
        </ApproverViewWrapper>
      ))}
      {!leaveItem.status.is_approved && (
        <ApproverViewWrapper>Not Approved yet</ApproverViewWrapper>
      )}
      {false && (
        <div className="actions">
          <button
            type="button"
            className="plain"
            onClick={() => cancel(leaveItem.resource.key)}
          >
            Cancel
          </button>
        </div>
      )}
    </LeaveProfileViewWrapper>
  );
};

LeaveProfileView.propTypes = {
  user: Proptypes.object,
  leaveItem: Proptypes.object,
};

const LeaveCommonViewWrapper = styled.div`
  padding: ${asRem(12)};

  .date {
    display: flex;
    align-items: baseline;

    & > span:first-child {
      font-weight: 500;
      font-size: ${asRem(16)};
      line-height: ${asRem(23)};
    }

    & > span:last-child {
      font-weight: 400;
      font-size: ${asRem(14)};
      line-height: ${asRem(20)};
      color: var(--color-grey);
    }
  }
  .req_at {
    font-weight: 400;
    font-size: ${asRem(12)};
    line-height: ${asRem(17)};
    color: var(--color-grey);
  }
  .approver {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: var(--color-theme-4);
  }
`;

const LeaveCommonView = ({ user, leaveItem }) => {
  return (
    <LeaveCommonViewWrapper>
      <div className="date">
        <span>
          {getFromToDateFormat(leaveItem?.event?.date, leaveItem.event.date_to, { format: 'ddd, MMM DD' })}
        </span>
        &nbsp;
        <span>
          {`(${getDiffBtwDates(leaveItem?.event?.date_to || leaveItem?.event?.date, leaveItem?.event?.date, ' ')?.label})`}
        </span>
      </div>
      <div className="divider" />
      <div className="req_at">
        {`${leaveItem?.leave?.kind} Requested @ ${friendlyDateTime(leaveItem?.leave?.requested_at, 'DD MMM, ddd, YYYY, h:mma')}`}
      </div>
      <div className="approver">
        {leaveItem.status.is_approved && leaveItem.leave.approved_by.map((approver, index) => (
          <span key={`${approver?.userKey}_${index}`}>
            Approved by
            {' '}
            {[user?.profile?.first_name, user?.profile?.last_name]?.filter(Boolean).join(' ')}
            {'. '}
          </span>
        ))}
      </div>
    </LeaveCommonViewWrapper>
  );
};

LeaveCommonView.propTypes = {
  user: Proptypes.object,
  leaveItem: Proptypes.object,
};

const MyLeavesWrapper = styled.div`
  min-height: ${asRem(95)};

  .divider {
    border: 0.5px solid var(--color-bg-10);
    margin: ${asRem(8)} 0 ${asRem(4)};
  }
`;

export const MyLeavesView = ({ leaveItem, showProfileView = true }) => {
  const userContext = useContext(UserContext);
  const { user } = userContext;

  return (
    <MyLeavesWrapper>
      {showProfileView ? (
        <LeaveProfileView user={user} leaveItem={leaveItem} />
      ) : (
        <LeaveCommonView user={user} leaveItem={leaveItem} />
      )}
    </MyLeavesWrapper>
  );
};

MyLeavesView.propTypes = {
  leaveItem: Proptypes.object,
  showProfileView: Proptypes.bool,
};

export default MyLeavesView;
