import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { friendlyDate } from 'lib/utils';
import { asRem } from 'lib/css';

const HolidayItemViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  .title, .event-box {
    padding: ${asRem(10)};
  }
  .title {
    flex: 1;
    padding: ${asRem(20)};
    font-size: ${asRem(32)};
    background-color: var(--color-bg-3);
    color: var(--color-text);
  }
  .event-box {
    min-height: ${asRem(80)};

    .event {
      font-size: ${asRem(22)};
    }

    .event-kind {
      padding-top: ${asRem(10)};
      font-size: ${asRem(14)};
    }
  }
`;

export const HolidayItemView = ({ event }) => {
  const colorCfg = {
    optionalholiday: 'var(--color-secondary)',
    publicholiday: 'var(--color-theme-5)',
  };
  return (
    <HolidayItemViewWrapper theme={{ bgColor: colorCfg?.[event?.event?.kind?.toLowerCase()] }}>
      <div className="title">
        {[
          friendlyDate(event.event.date, null, 'MMM DD, ddd'),
          event.event.date_to && event.event.date_to !== event.event.date && friendlyDate(event.event.date_to, null, 'MMM DD, ddd'),
        ]?.filter(Boolean)?.join(' - ')}
      </div>
      <div className="event-box">
        <div className="event">{event.event.name}</div>
        <div className="event-kind">{event?.event?.kind}</div>
      </div>
    </HolidayItemViewWrapper>
  );
};

HolidayItemView.propTypes = {
  event: PropTypes.object.isRequired,
};
