import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { ListView } from 'core/List';
import { dataFromPath } from 'lib/utils';
import { StatusError, StatusLoading } from './Status';
import { CardView } from './Card';

const ListControllerWrapper = styled.div`
table {
  border-collapse: separate;
}
`;

export const ListController = ({
  query,
  responseKey,
  itemsKey,
  title = '',
  rows,
  header,
  reload,
  actions = null,
  variables = null,
  actionPath = null,
  actionName = null,
  rowHead = null,
  useTable = true,
  customError = null,
  ...rest
}) => {
  const [lastReload, setLastReload] = useState(0);
  const [previousPage, setPreviousPage] = useState(null);
  const [pageKey, setPageKey] = useState(null);
  const {
    loading,
    data,
    error,
    refetch,
  } = useQuery(query, {
    variables: { pageKey, ...(variables || {}) },
  });

  if (!data && loading) return (<StatusLoading />);
  if (!data && error) return (<StatusError error={error} />);

  const resp = dataFromPath(data, responseKey);
  const items = dataFromPath(resp, itemsKey) || [];

  const gotoNextPage = () => {
    setPreviousPage(pageKey);
    setPageKey(resp.next_page_key);
  };

  const gotoPrevPage = () => {
    setPageKey(previousPage);
    setPreviousPage(null);
  };

  if (reload !== lastReload) {
    console.log('Reloading', reload);
    refetch();
    setLastReload(reload);
  }

  return (
    <ListControllerWrapper>
      {error && (
        <StatusError error={error} />
      )}
      {useTable ? (
        <ListView
          title={title}
          items={items}
          actions={(actions || actionPath) && (
            <div>
              {actions && actions(resp)}
              {actionPath && (
                <Link to={actionPath} className="primary">
                  {actionName || 'New'}
                </Link>
              )}
            </div>
          )}
        >
          {header && header(items)}
          <table>
            <thead>
              {rowHead && rowHead(resp)}
            </thead>
            <tbody>
              {rows(items, resp)}
            </tbody>
          </table>
          <div className="page-tools">
            {resp.next_page_key && (
              <button
                type="button"
                className="plain"
                onClick={gotoNextPage}
              >
                Next
              </button>
            )}
            {previousPage && (
              <button
                type="button"
                className="plain"
                onClick={gotoPrevPage}
              >
                Prev
              </button>
            )}
          </div>
        </ListView>
      ) : (
        <CardView header={header} title={title} items={items} customError={customError} {...rest}>
          {rows(items, resp)}
        </CardView>
      )}
    </ListControllerWrapper>
  );
};

ListController.propTypes = {
  query: PropTypes.object.isRequired,
  responseKey: PropTypes.string.isRequired,
  itemsKey: PropTypes.string.isRequired,
  variables: PropTypes.object,
  title: PropTypes.string,
  actionPath: PropTypes.string,
  actionName: PropTypes.string,
  rowHead: PropTypes.func,
  rows: PropTypes.func,
  header: PropTypes.func,
  actions: PropTypes.func,
  reload: PropTypes.number,
  useTable: PropTypes.bool,
  customError: PropTypes.element,
};
