import React from 'react';
import styled from 'styled-components';
import { ListController } from 'core/ListController';
import { RzSelect } from 'core/Select';
import { useYearPicker } from 'core/hooks';
import PropTypes from 'prop-types';
import { CalendarEventListQuery } from './query';
import { HolidayItemView } from './HolidayItem';

const HolidaysWrapper = styled.div`
  >.years {
    .rz-select {
      margin: var(--layout-padding);
    }
  }
`;

export const Holidays = ({ enableDate = false, ...rest }) => {
  const { year, setYear, years } = useYearPicker();

  return (
    <HolidaysWrapper>
      {enableDate && (
        <div className="years">
          <RzSelect
            options={years}
            defaultValue={year.name}
            keyPath="name"
            valuePath="name"
            onChange={(val) => setYear(val)}
          />
        </div>
      )}
      <ListController
        query={CalendarEventListQuery}
        responseKey="staff_emp_calendar_event"
        itemsKey="events"
        title="Upcoming Holidays"
        variables={{ year: year.year, upcoming: true }}
        useTable={false}
        rows={(items) => items.map((event) => (
          <HolidayItemView key={event.resource.key} event={event} />
        ))}
        {...rest}
      />
    </HolidaysWrapper>
  );
};

Holidays.propTypes = {
  enableDate: PropTypes.bool,
};

export default Holidays;
