import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import { UserContext } from './UserContext';
import { Pages } from './pages';
import { SvgIcon } from './SvgIcon';

const TopBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100% - ${asRem(1)});
  padding: ${asRem(20)} ${asRem(15)};

  h1 {
    padding: 0 var(--layout-padding-contained);
    font-size: ${asRem(16)};
    line-height: ${asRem(21)};
    align-self: center;
    display: block;
  }

  aside {
    display: flex;
    align-items: center;
    button {
      display: block;
      margin-top: ${asRem(7)}
    }
    img.profile {
      border-radius: 50%;
    }
  }

  a {
    background-color: transparent;
    padding: 0;
  }

  @media screen and (max-width: ${asRem(767)}) {
    height: 100%;

    .notify-icon {
      padding: ${asRem(0)} ${asRem(25)}};
    }
  }
`;

export const TopBar = () => {
  const userContext = useContext(UserContext);

  return (
    <TopBarWrapper>
      <Link to="/">
        <SvgIcon icon="logo" />
      </Link>
      <aside>
        <Link to={Pages.staff.profile.meta.url}>
          {userContext?.user?.profile?.picture_url ? (
            <img className="profile" src={userContext?.user?.profile?.picture_url} alt="Profile" height="28" width="28" />
          ) : (
            <SvgIcon icon="profile_placeholder" />
          )}
        </Link>
      </aside>
    </TopBarWrapper>
  );
};

TopBar.propTypes = {
};
