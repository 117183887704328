export function getLocalData(key, defaultValue) {
  const val = localStorage.getItem(key);

  if (val === undefined) {
    return defaultValue;
  }

  return val;
}

export function getToken() {
  return localStorage.getItem('rztoken');
}

export function saveToken(token) {
  return localStorage.setItem('rztoken', token);
}

export function removeToken() {
  return localStorage.removeItem('rztoken');
}
