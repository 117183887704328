import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';
import { Breadcrumb } from 'core/Breadcrumb';

const OnLoginCallback = lazy(() => import('./OnLoginCallback'));

export const PagesRoutes = () => {
  return (
    <Suspense fallback={<>...</>}>
      <div>
        <Breadcrumb className="hide-on-fullscreen" />
        <Routes>
          <Route
            path={Pages.pages.onLoginCallback.meta.path}
            element={(<OnLoginCallback />)}
          />
        </Routes>
      </div>
    </Suspense>
  );
};
